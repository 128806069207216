<template>
    <div
        ref="element"
        :class="classes"
    >
        <slot />
    </div>
</template>

<script setup lang="ts">
import { cva } from 'class-variance-authority'
import { ref } from 'vue'

interface Props {
    grid?: boolean
    size?: 'large' | 'medium' | 'small'
}

const props = withDefaults(defineProps<Props>(), {
    grid: false,
    size: 'medium',
})

const element = ref<HTMLDivElement>()
const classes = cva('gap-6', {
    variants: {
        size: {
            large: 'max-w-7xl',
            medium: 'max-w-3xl',
            small: 'max-w-md',
        },
        grid: {
            true: 'grid',
            false: 'flex flex-col',
        },
    },
    compoundVariants: [
        {
            size: ['large', 'medium', 'small'],
            class: 'mx-auto px-6',
        },
    ],
})({
    size: props.size,
    grid: props.grid,
})

defineExpose({ element })
</script>
