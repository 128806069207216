import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import { createPlugin } from '@shared/helpers'
import { type Plugin, hasInjectionContext, inject } from 'vue'
import { useRoute } from '@modules/Router'
import type { Name } from '@modules/Router/Config/names.ts'
import application from '@modules/App/Foundation/application.ts'
import { useApi } from '@modules/Http'
import type { RequestInterceptor } from '@modules/Http/types.ts'

window.Pusher = Pusher

const echoSymbol = Symbol('$echo')

const makeEcho = (endpoint: Name): Echo => {
    return application.make<Echo>('echo', () => {
        const api = useApi()
        const route = useRoute()

        return new Echo({
            broadcaster: 'pusher',
            key: import.meta.env.VITE_PUSHER_APP_KEY,
            wsHost: import.meta.env.VITE_PUSHER_HOST,
            cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
            enabledTransports: ['ws', 'wss'],
            authorizer: (channel: any) => ({
                authorize: (socketId: string, callback: any) => {
                    api.post(route(endpoint), {
                        socket_id: socketId,
                        channel_name: channel.name,
                    })
                        .then((response) => {
                            callback(false, response.data)
                        })
                        .catch((error) => {
                            callback(true, error)
                        })
                },
            }),
        })
    })
}

export const createEcho: Plugin = {
    install(app, { endpoint }) {
        createPlugin(app, makeEcho(endpoint), echoSymbol)
    },
}

export const echoHandler = (): RequestInterceptor => {
    return {
        onFulfilled(response, next) {
            response.headers.set('X-Socket-ID', useEcho().socketId(), true)

            return next(response)
        },
    }
}

// TODO: Change to a dynamic endpoint based on domain
export function useEcho(): Echo {
    return hasInjectionContext()
        ? inject(echoSymbol, makeEcho('group.broadcast.auth'))
        : makeEcho('group.broadcast.auth')
}
