export const color = {
    text: {
        muted: 'text-gray-500 dark:text-dark-300',
        default: 'text-gray-900 dark:text-white',
        hover: 'hover:text-gray-900 dark:hover:text-white',
        focus: 'focus-visible:text-gray-900 dark:focus-visible:text-white',
    },

    focus: {
        button: {
            default: 'focus-visible:outline-primary',
            danger: 'focus-visible:outline-red-600',
        },
        item: {
            default: 'focus-visible:border-primary focus-visible:ring-primary',
        },
    },

    hover: {
        item: {
            default: 'hover:border-primary hover:ring-primary',
        },
    },

    background: {
        card: 'bg-white dark:bg-dark',
    },

    border: {
        popup: 'border-gray-200 dark:border-dark-700',
        divider: 'divide-gray-200 dark:divide-dark-700',
    },

    skeleton: {
        background: 'bg-gray-200 dark:bg-dark-700',
        border: 'border-gray-200 dark:border-dark-700',
        text: 'text-gray-200 dark:text-dark-700',
    },
}

export const structure = {
    spacing: {
        button: 'py-2 px-4',
        toolbar: 'p-2.5',
        card: 'p-4',
    },

    size: {
        toolbar: 'size-4',
    },

    card: {
        body: 'flex flex-col gap-3',
    },

    rounded: 'rounded-lg',
    container: 'flex flex-col gap-y-6',
}

export const shadow = {
    panel: 'shadow-lg',
}

export const transition = {
    text: 'transition-all duration-200 ease-in-out',
}

export const focusItem = [color.focus.button.default, 'outline-none']

export const textWithHover = [color.text.muted, color.text.hover, transition.text]

export const buttonStructure = [structure.spacing.button, structure.rounded, 'text-sm font-medium outline-none']

export const toolbarItemIcon = [
    ...textWithHover,
    ...focusItem,
    structure.rounded,
    structure.spacing.toolbar,
    color.background.card,
]
