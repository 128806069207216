import type { ResponseInterceptor } from '@modules/Http/types'
import Notifications from 'notiwind'
import { type Plugin } from 'vue'
import application from '@modules/App/Foundation/application.ts'
import Popup from '@modules/Popup/popup.ts'

const makePopup = (): Popup => {
    return application.make('popup', () => new Popup())
}

export const notificationHandler = (): ResponseInterceptor => {
    const popup = makePopup()

    return {
        onFulfilled: (response, next) => {
            popup.add(response.data?.notification)

            return next(response)
        },
        onRejected: (error, next) => {
            popup.add(error.response.data?.notification)

            return next(error)
        },
    }
}

export const createNotification: Plugin = {
    install(app) {
        app.use(Notifications)
    },
}

export const usePopup = (): Popup => {
    return makePopup()
}
