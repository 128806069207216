import '@group/css/app.css'
import '@shared/Css/tailwind.css'
import 'floating-vue/dist/style.css'

import { createHead } from 'unhead'
import { authHandler } from '@modules/Auth'
import { createStore } from '@modules/Store'
import { createIcons } from '@shared/helpers'
import { type DefineComponent } from 'vue'
import { createRoute, useRoute } from '@modules/Router'
import { createEcho, echoHandler, useEcho } from '@modules/Echo'
import { authTransitionHandler } from '@group/Utils/handlers.ts'
import { createApi, useApi, csrfTokenHandler } from '@modules/Http'
import { createNotification, notificationHandler } from '@modules/Popup'
import { createApplication, renderPageHandler, createSentry } from '@modules/App'

import { fas } from '@fortawesome/free-solid-svg-icons'
import { faPenToSquare, faPaperPlane } from '@fortawesome/free-regular-svg-icons'

import Loading from '$group/Components/Loading.vue'

createApplication({
    title: document.getElementsByTagName('title')[0]?.innerText ?? 'Ranking The Gems',
    pages: import.meta.glob<DefineComponent>('./Views/Pages/*/*.vue'),
    loader: {
        delay: 400,
        component: Loading,
        skeletons: import.meta.glob('./Views/Skeletons/*/*Skeleton.vue', { eager: true }),
    },
    setup: (app) => {
        createHead()
        createIcons([fas, faPenToSquare, faPaperPlane])

        /**
         * Order matters for the app uses and the
         * handlers for the api
         */
        app.use(createSentry)
        app.use(createApi, {
            request: {
                echoHandler,
            },
            response: {
                csrfTokenHandler,
                authTransitionHandler,
                renderPageHandler,
                authHandler,
                notificationHandler,
            },
        })
        app.use(createRoute)
        app.use(createEcho, {
            endpoint: 'group.broadcast.auth',
        })
        app.use(createNotification)
        app.use(createStore, (store) => {
            store.$api = useApi()
            store.$echo = useEcho()
            store.$route = useRoute()
        })

        app.mount('#app')
    },
})
