import { tap } from 'lodash'
import { notify } from 'notiwind'
import { markRaw } from 'vue'
import Simple from '@modules/Popup/Components/Toats/Simple.vue'
import type { PopupData, PopupType } from '@modules/Popup/types.ts'

class Popup {
    private readonly toast: PopupType = {
        queue: [],
        loaded: false,
    }

    private readonly notification: PopupType = {
        queue: [],
        loaded: false,
    }

    public add(data: PopupData) {
        data.group === 'toast' ? this.addToast(data) : this.addNotification(data)
    }

    public addToast(data: PopupData) {
        this.toast.loaded ? this.handle(data) : this.toast.queue.push(data)
    }

    public addNotification(data: PopupData) {
        this.notification.loaded ? this.handle(data) : this.notification.queue.push(data)
    }

    public changeToastState(condition: boolean) {
        this.toast.loaded = tap(condition, () => {
            condition
                ? this.toast.queue.forEach((data) => {
                      this.handle(data)
                  })
                : (this.toast.queue = [])
        })
    }

    public changeNotificationState(condition: boolean) {
        this.notification.loaded = tap(condition, () => {
            condition
                ? this.notification.queue.forEach((data) => {
                      this.handle(data)
                  })
                : (this.notification.queue = [])
        })
    }

    private handle(data: PopupData) {
        notify(
            {
                data: data.data,
                group: data.group,
                component: markRaw(Simple),
                dismissible: data.dismissible,
            },
            data.timeout
        )
    }
}

export default Popup
