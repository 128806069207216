import type { ResponseInterceptor } from '@modules/Http/types'
import { useApplication } from '@modules/App'
import { useRoute } from '@modules/Router'

export function authTransitionHandler(): ResponseInterceptor {
    const route = useRoute()
    const application = useApplication()

    return {
        onFulfilled: (response, next) => {
            if (response.config.url === route('group.api.auth.attempt')) {
                application.setChangingViewSection('root').startViewChanging()
            }

            return next(response)
        },
    }
}
