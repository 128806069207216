<template>
    <div ref="skeleton">
        <SkeletonRepeat :total="total">
            <slot />
        </SkeletonRepeat>
    </div>
</template>

<script setup lang="ts">
import SkeletonRepeat from '@modules/App/Components/Skeletons/SkeletonRepeat.vue'
import { onMounted, ref } from 'vue'
import { isNil } from 'lodash'

interface Props {
    total?: number
}

withDefaults(defineProps<Props>(), {
    total: 1,
})

const skeleton = ref<HTMLElement>()

onMounted(() => {
    if (!isNil(skeleton.value?.children)) {
        for (let i = 0; i < skeleton.value.children.length; i++) {
            const child = skeleton.value.children[i] as HTMLElement

            child.classList.add('animate-pulse')
        }
    }
})
</script>
