<template>
    <Skeleton :class="{ 'flex flex-1': !isRoot }">
        <template v-if="isRoot">
            <div class="flex min-h-screen flex-col">
                <div>
                    <SkeletonContent class="h-16 w-full !rounded-none" />
                </div>
                <div class="flex flex-1 py-12">
                    <div class="w-full">
                        <slot />
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="w-full">
                <slot />
            </div>
        </template>
    </Skeleton>
</template>

<script setup lang="ts">
import Skeleton from '@modules/App/Components/Skeletons/Skeleton.vue'
import SkeletonContent from '@modules/App/Components/Skeletons/SkeletonContent.vue'
import { useApplication } from '@modules/App'
import { computed } from 'vue'

const application = useApplication()

const isRoot = computed(() => application.changingViewSection === 'root')
</script>
