<template>
    <div class="flex min-h-screen flex-col items-center pt-6 sm:justify-center">
        <div>
            <slot name="header" />
        </div>
        <div class="w-full max-w-md px-6 pt-6">
            <slot />
        </div>
    </div>
</template>
